import { Button, makeStyles, Typography } from '@material-ui/core';
import Loading from 'components/loading/Loading';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useState } from 'react';
import { api } from 'services/api';
import { CreditCard } from 'types/creditCard';
import BillingCardForm from '../BillingCardForm';
import { useBilling } from '../hooks/useBilling';
import { useBillingCardValidation } from '../validation/billingCardValidation';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  card: {
    position: 'relative',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    background: '#fff',
    border: '1px solid #eee',
    padding: 15,
    flex: 1,
  },
  actions: {
    alignSelf: 'center',
  },
  noCard: {
    flex: 1,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
});

const BillingCard: React.FC = () => {
  const { creditCard, setCreditCard } = useBilling();
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const [dialogCard, setDialogCard] = useState(false);
  const [validation, setValidation, validate] = useBillingCardValidation();
  const [card, setCard] = useState<CreditCard>({
    name: '',
    expiration_date: '',
    cvv: '',
    number: '',
    document: '',
    document_type: 'cpf',
  });

  function handleDelete() {
    if (!creditCard) return;

    setSaving(true);

    api
      .delete(`/creditCards/${creditCard.id}`)
      .then(() => {
        handleOpen('Excluído');
        setCreditCard(null);
      })
      .catch(err => handleOpen(err.response ? err.response.data.error : 'Não foi possível excluir o cartão'))
      .finally(() => setSaving(false));
  }

  function handleValidation(handleCloseDialog: () => void, e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(card)
      .then(() => handleSubmit(handleCloseDialog))
      .catch(err => console.error(err));
  }

  function handleSubmit(handleCloseDialog: () => void) {
    setSaving(true);

    api
      .post('/creditCards', card)
      .then(response => {
        setCreditCard(response.data);
        handleCloseDialog();
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar o cartão');
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof CreditCard, value: string) {
    setCard(card => ({
      ...card,
      [index]: value,
    }));
  }

  return (
    <div className={classes.container}>
      {dialogCard && (
        <BillingCardForm
          onExited={() => setDialogCard(false)}
          validation={validation}
          card={card}
          handleChange={handleChange}
          handleValidation={handleValidation}
          saving={saving}
        />
      )}
      {saving && !dialogCard && <Loading />}
      {creditCard ? (
        <div className={classes.card}>
          <div>
            <Typography variant="subtitle2">{creditCard.brand}</Typography>
            <Typography>Terminado em {creditCard.last_digits}</Typography>
            <Typography>Validade {creditCard.expiration_date}</Typography>
            <Typography color="textSecondary" variant="caption">
              As faturas serão pagas com esse cartão
            </Typography>
          </div>
          <div className={classes.actions}>
            <Button size="small" variant="text" color="primary" onClick={handleDelete}>
              Excluir
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.noCard}>
          <Typography color="textSecondary" variant="body2">
            Nenhum cartão de crédito inserido
          </Typography>
          <Button variant="text" color="primary" onClick={() => setDialogCard(true)}>
            Incluir cartão de crédito
          </Button>
        </div>
      )}
    </div>
  );
};

export default BillingCard;
